define("city-map/templates/components/slider-filter", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HnkkcG4w",
    "block": "[[[1,[28,[35,0],null,[[\"start\",\"min\",\"max\",\"connect\",\"tooltips\",\"step\",\"behaviour\",\"on-slide\"],[[30,0,[\"start\"]],[30,0,[\"min\"]],[30,0,[\"max\"]],true,[28,[37,1],[[30,0,[\"format\"]],[30,0,[\"format\"]]],null],1,\"drag\",\"sliderChanged\"]]]],[1,\"\\n\\n\"],[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"range-slider\",\"array\",\"yield\"]]",
    "moduleName": "city-map/templates/components/slider-filter.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});