define("city-map/components/needs-async-state", ["exports", "@nycplanning/ember/components/needs-async-state"], function (_exports, _needsAsyncState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _needsAsyncState.default;
    }
  });
});