define("city-map/components/mapbox-gl-on", ["exports", "ember-mapbox-gl/components/mapbox-gl-on"], function (_exports, _mapboxGlOn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _mapboxGlOn.default;
    }
  });
});