define("city-map/templates/components/tooltip-renderer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "+GgJMWRN",
    "block": "[[[41,[30,1],[[[1,\"  \"],[1,[30,0,[\"renderedText\"]]],[1,\"\\n\"]],[]],null]],[\"@template\"],false,[\"if\"]]",
    "moduleName": "city-map/templates/components/tooltip-renderer.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});