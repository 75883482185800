define("city-map/components/labs-search", ["exports", "@nycplanning/ember/components/labs-search"], function (_exports, _labsSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _labsSearch.default;
    }
  });
});