define("city-map/routes/application", ["exports", "@ember/routing/route", "rsvp", "@ember/service"], function (_exports, _route, _rsvp, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ApplicationRoute = (_dec = (0, _service.inject)('layerGroups'), (_class = class ApplicationRoute extends _route.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "layerGroupService", _descriptor, this);
      _defineProperty(this, "beforeModel", transition => {
        // only transition to about if index is loaded and there is no hash
        if (transition.intent.url === '/' && window.location.href.split('#').length < 2) {
          this.transitionTo('about');
        }
      });
    }
    async model() {
      const layerGroups = await this.store.query('layer-group', {
        'layer-groups': [{
          id: 'citymap',
          visible: true
        }, {
          id: 'street-centerlines',
          visible: true
        }, {
          id: 'pierhead-bulkhead-lines',
          visible: true
        }, {
          id: 'street-sections',
          visible: false
        }, {
          id: 'amendments',
          visible: true
        }, {
          id: 'amendments-pending',
          visible: false
        }, {
          id: 'arterials',
          visible: false
        }, {
          id: 'name-changes',
          visible: false
        }, {
          id: 'paper-streets',
          visible: false
        }, {
          id: 'stair-streets',
          visible: false
        }, {
          id: 'zoning-districts',
          visible: false
        }, {
          id: 'commercial-overlays',
          visible: false
        }, {
          id: 'special-purpose-districts',
          visible: false
        }, {
          id: 'tax-lots',
          visible: false
        }, {
          id: 'floodplain-pfirm2015',
          visible: false
        }, {
          id: 'floodplain-efirm2007',
          visible: false
        }, {
          id: 'aerials',
          visible: false
        }]
      });
      const amendmentsFill = await this.store.peekRecord('layer', 'citymap-amendments-fill');
      const {
        mapboxStyle: initialStyle
      } = layerGroups.get('meta');
      return (0, _rsvp.hash)({
        layerGroups,
        amendmentsFill,
        initialStyle
      });
    }

    /**
     * @override: ember lifecycle
     */
    setupController(controller, model) {
      const {
        layerGroups
      } = model;
      this.get('layerGroupService').initializeObservers(layerGroups, controller);
      super.setupController(controller, model);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "layerGroupService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ApplicationRoute;
});