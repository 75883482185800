define("city-map/templates/components/mapbox-gl-popup", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "m84CXzBW",
    "block": "[[[8,[39,0],null,[[\"@destinationElement\"],[[30,0,[\"domContent\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[18,1,[[28,[37,2],null,[[\"on\"],[[50,\"mapbox-gl-on\",0,null,[[\"eventSource\"],[[30,0,[\"popup\"]]]]]]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"ember-wormhole\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "city-map/templates/components/mapbox-gl-popup.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});