define("city-map/components/slider-filter", ["exports", "@ember/component", "@ember/object", "moment"], function (_exports, _component, _object, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const fromEpoch = function (number, format) {
    return (0, _moment.default)(number, 'X').utc().format(format);
  };

  // Jan 1, 1903 to present
  const defaultStart = [-2114380799, parseInt((0, _moment.default)().utc().endOf('year').format('X'), 10)];
  let SliderFilterComponent = (_class = class SliderFilterComponent extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "layer", void 0);
      _defineProperty(this, "start", defaultStart);
      _defineProperty(this, "min", defaultStart[0]);
      _defineProperty(this, "max", defaultStart[1]);
      _defineProperty(this, "format", {
        to: number => fromEpoch(number, 'YYYY'),
        from: number => fromEpoch(number, 'YYYY')
      });
    }
    sliderChanged(_ref) {
      let [min, max] = _ref;
      const layer = this.get('layer');

      // because the slider returns unix epochs based on its own step increment,
      // get the startOf() the min timestamp's year, and the endOf() of the max timestamp's year
      const minStart = parseInt((0, _moment.default)(min, 'X').utc().startOf('year').format('X'), 10);
      const maxEnd = parseInt((0, _moment.default)(max, 'X').utc().endOf('year').format('X'), 10);
      const filter = this.generateExpression(minStart, maxEnd);
      layer.set('filter', filter);
    }
    generateExpression(min, max) {
      return ['all', ['>=', 'effective', min], ['<=', 'effective', max]];
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "sliderChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sliderChanged"), _class.prototype)), _class);
  _exports.default = SliderFilterComponent;
});