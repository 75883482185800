define("city-map/routes/data", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DataRoute extends _route.default {
    async model() {
      const sources = this.store.peekAll('source');
      return sources.toArray().uniqBy('meta.description');
    }
  }
  _exports.default = DataRoute;
});