define("city-map/components/map-popup-content", ["exports", "@ember/component", "@ember/object", "moment"], function (_exports, _component, _object, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function splitBBL(bbl) {
    const bblString = bbl.toString();
    const boro = bblString.substring(0, 1);
    const block = parseInt(bblString.substring(1, 6), 10);
    const lot = parseInt(bblString.substring(6), 10);
    return {
      boro,
      block,
      lot
    };
  }
  const boroLookup = {
    1: 'Manhattan',
    2: 'Bronx',
    3: 'Brooklyn',
    4: 'Queens',
    5: 'Staten Island'
  };
  let MapPopupContent = (_dec = (0, _object.computed)('features'), _dec2 = (0, _object.computed)('features'), _dec3 = (0, _object.computed)('features'), _dec4 = (0, _object.computed)('features'), (_class = class MapPopupContent extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "features", []);
      _defineProperty(this, "onHoverListItem", () => {});
      _defineProperty(this, "onMouseLeave", () => {});
    }
    get cleanAlterations() {
      const features = this.get('features');
      if (features === null) return features;

      // add a timestamp property to sort by
      const cleanAlterations = features.filter(d => d.properties.type === 'alteration').map(feature => {
        const {
          properties
        } = feature;
        const {
          altmappdf,
          effective,
          status
        } = properties;
        const pdf = altmappdf.split('/').pop();
        return {
          feature,
          status,
          timestamp: parseInt((0, _moment.default)(effective).format('X'), 10),
          pdflink: `https://nycdcp-dcm-alteration-maps.nyc3.digitaloceanspaces.com/${pdf}`,
          pdf,
          effective: (0, _moment.default)(effective).format('MMM D, YYYY')
        };
      });
      return cleanAlterations.sort((a, b) => a.timestamp < b.timestamp);
    }
    get cleanLots() {
      const features = this.get('features');
      if (features === null) return features;

      // add a timestamp property to sort by
      const cleanLots = features.filter(d => d.properties.type === 'taxlot').map(feature => {
        const {
          properties
        } = feature;
        const {
          bbl,
          address
        } = properties;
        const BBLparts = splitBBL(bbl);
        return {
          feature,
          bbl,
          address,
          boro: BBLparts.boro,
          boroName: boroLookup[BBLparts.boro],
          block: BBLparts.block,
          lot: BBLparts.lot
        };
      });
      return cleanLots;
    }
    get streetNameChanges() {
      const features = this.get('features');
      if (features === null) return features;

      // add a timestamp property to sort by
      const streetNameChanges = features.filter(d => d.properties.type === 'streetnamechange');
      streetNameChanges.forEach(d => {
        d.properties.lleffectdt = (0, _moment.default)(d.properties.lleffectdt, 'MM/DD/YYYY').format('MMM D, YYYY'); // eslint-disable-line
        return d;
      });
      return streetNameChanges;
    }
    get sectionMapLink() {
      const features = this.get('features');
      if (features === null) return features;
      const sectionMapLink = features.filter(d => d.properties.type === 'streetsect').map(feature => {
        const {
          properties
        } = feature;
        const {
          do_path,
          last_date,
          boro
        } = properties;
        return {
          feature,
          do_path,
          boro,
          boroname: boroLookup[boro],
          last_date: (0, _moment.default)(last_date).format('MMM D, YYYY'),
          section_info: do_path === null ? null : do_path.split('.com/')[1]
        };
      });
      return sectionMapLink;
    }
    handleHoverListItem(feature) {
      this.get('onHoverListItem')(feature);
    }
    mouseLeave() {
      this.get('onMouseLeave')();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "cleanAlterations", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "cleanAlterations"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cleanLots", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "cleanLots"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "streetNameChanges", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "streetNameChanges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sectionMapLink", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "sectionMapLink"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleHoverListItem", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleHoverListItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mouseLeave", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "mouseLeave"), _class.prototype)), _class));
  _exports.default = MapPopupContent;
});