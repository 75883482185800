define("city-map/utils/precision-round", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = precisionRound;
  function precisionRound(number, precision) {
    const factor = 10 ** precision;
    return Math.round(number * factor) / factor;
  }
});