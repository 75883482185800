define("city-map/templates/feedback", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "DUkaSks5",
    "block": "[[[10,0],[14,0,\"content-close-button-container\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"content-close-button\"],[4,[38,1],[\"click\",[28,[37,2],[[28,[37,3],[[30,0,[\"closed\"]]],null],true],null]],null]],[[\"@route\"],[\"application\"]],[[\"default\"],[[[[1,\"x\"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"content-area cell large-5 large-order-3 xxlarge-4\"],[12],[1,\"\\n\\n  \"],[10,\"h1\"],[14,0,\"header-xlarge\"],[12],[1,\"Feedback\"],[13],[1,\"\\n\\n  \"],[10,\"h3\"],[14,0,\"header-medium small-margin-bottom\"],[12],[1,\"City Map Information\"],[13],[1,\"\\n\\n  \"],[10,2],[14,0,\"text-small\"],[12],[1,\"This street information has never been so easy to access, but NYC Street Map is only a starting point for research about the City’s streets. It lacks some information on the City Map and may have inaccuracies.  All information gathered from NYC Street Map must be confirmed based on the official City Map records.\"],[13],[1,\"\\n\\n  \"],[1,[28,[35,4],null,[[\"class\"],[\"text-small\"]]]],[1,\"\\n\\n  \"],[10,\"h3\"],[14,0,\"header-medium small-margin-bottom\"],[12],[1,\"NYC Street Map Application Feedback\"],[13],[1,\"\\n\\n  \"],[10,2],[12],[1,\"To let \"],[10,3],[14,6,\"https://twitter.com/nycplanninglabs\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[10,\"strong\"],[12],[1,\"Planning Labs\"],[13],[13],[1,\" know how this app could be better, \"],[10,3],[14,6,\"https://github.com/NYCPlanning/labs-citymap/issues/\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[10,\"strong\"],[12],[1,\"add a GitHub Issue\"],[13],[13],[1,\", or email \"],[10,3],[14,6,\"mailto:streetmap@planning.nyc.gov\"],[12],[10,\"strong\"],[12],[1,\"streetmap@planning.nyc.gov\"],[13],[13],[1,\".\"],[13],[1,\"\\n\\n  \"],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"link-to\",\"on\",\"fn\",\"mut\",\"boro-office-contact-callout\",\"component\",\"-outlet\"]]",
    "moduleName": "city-map/templates/feedback.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});